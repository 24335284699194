<template>
  <v-tooltip bottom>
    <v-select
      :items="items"
      item-text="text"
      item-value="value"
      v-model="mode"
      slot="activator"
      hide-details
      :disabled="disable"
      class="pt-0"
    >
      <template slot="selection" slot-scope="props">
        <v-list-tile-action class="mode-selection">
          <v-icon>{{ props.item.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ disable ? $t(`shared.mode.${props.item.exc}.text`) : $t(`shared.mode.${props.item.type}.text`) }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
      <template slot="item" slot-scope="props">
        <v-list-tile-action class="mode-selection">
          <v-icon>{{ props.item.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ $t(`shared.mode.${props.item.type}.text`) }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ $t(`shared.mode.${props.item.type}.desc`) }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-select>
    <span>{{ $t('shared.mode.text') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["disable"],
  data() {
    return {
      mode: true,
      items: [
        {
          icon: "visibility",
          value: true,
          text: "View Mode",
          desc: "Browse the document.",
          type: "view",
          exc: "view_only"
        },
        {
          icon: "edit",
          value: false,
          text: "Edit Mode",
          desc: "Edit the document.",
          type: "edit"
        }
      ]
    };
  },
  watch: {
    mode() {
      this.$emit("onSwitchMode", this.mode);
    }
  }
};
</script>

<style>
.mode-selection {
  min-width: 40px;
}
</style>
