<template>
    <div v-if="item">
        <v-layout row wrap py-2>
            <v-flex xs12>
                <v-card>
                    <v-card-title>
                        <v-icon color="secondary">next_week</v-icon>
                        <span>&ensp;</span>
                        <h3>{{ $t('menu.ApplicationDetail') }}</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-flex>
                            <card-location v-for="(location, index) in (this.confirm ? item.location.filter(x => x.use) : item.location)"
                                           :key="location.id"
                                           :id="location.id"
                                           :item="location"
                                           :index="index"
                                           :application="item"
                                           :removeLocation="removeLocation"
                                           :mode="ownMode"
                                           :errors="errors"
                                           :examination="item.examination ? item.examination.find(x => x.company_id === location.company_id) : null"
                                           :type="locationType">
                            </card-location>
                        </v-flex>
                        <v-flex v-if="mode">
                            <v-text-field
                                :label="$t('apply.applicant')"
                                :disabled="ownMode"
                                v-model="item.leaser.name"
                            ></v-text-field>
                        </v-flex>
                        <v-flex v-if="mode">
                            <v-text-field
                                :label="$t('apply.date_of_application')"
                                :disabled="ownMode"
                                v-model="item.created_at"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-select
                                :label="$t('apply.application_for_use')"
                                v-model="item.appli_id"
                                :items="applis"
                                item-value="id"
                                item-text="name"
                                :disabled="ownMode"
                                :error-messages="errors['appli_id']"
                            ></v-select>
                        </v-flex>
                        <v-flex>
                            <label>利用用途</label>
                            <v-flex xs9>
                                <v-checkbox :disabled="ownMode" v-model="item.has_notifications" label="通知に使用したい"  :error-messages="errors['has_notifications']"></v-checkbox>
                                <v-checkbox :disabled="ownMode" v-model="item.has_stamp_free" label="スタンプラリーに使用したい" :error-messages="errors['has_stamp_free']"></v-checkbox>
                                <v-checkbox :disabled="ownMode" v-model="item.has_campaign" label="キャンペーンに使用したい" :error-messages="errors['has_campaign']"></v-checkbox>
                                <span style="color: red;">{{(errors.use_check)?errors.use_check[0]:""}}</span>
                            </v-flex>
                        </v-flex>
                        <v-flex>
                            <v-textarea
                                :label="$t('apply.other')"
                                :disabled="ownMode"
                                v-model="item.remarks"
                                :error-messages="errors['remarks']"
                            ></v-textarea>
                        </v-flex>
                        <v-flex>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="item.desired_start_date"
                                        :label="$t('apply.desired_start_date')"
                                        readonly
                                        :disabled="ownMode"
                                        :error-messages="errors['desired_start_date']"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="item.desired_start_date" no-title scrollable>
                                    <div class="flex-grow-1"></div>
                                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex style="width: 100px;">
                            <v-text-field
                                :label="$t('apply.usage_period')"
                                :disabled="ownMode"
                                v-model="item.usage_duration"
                                placeholder="3"
                                :error-messages="errors['usage_duration']"
                                suffix="ヶ月間"
                            ></v-text-field>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="confirm" outline color="secondary" @click.stop="confirm = ownMode = false">{{ $t('common.cancel') }}</v-btn>
                        <v-btn v-else outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn v-if="!mode && !confirm" outline color="primary" @click.stop="makeApplication">{{ $t('common.apply') }}</v-btn>
                        <v-btn v-if="!mode && confirm" outline color="primary" @click.stop="finalizeConfirmation">{{ $t('common.confirm') }}</v-btn>
                        <v-btn v-if="mode && item.location.filter(x => x.application_location.status === this.location_statuses.STATUS_APPROVED).length > 0" outline color="primary"
                               @click.stop="finalizeApplication">
                            結果を確定
                        </v-btn>
                        <v-btn v-if="mode && item.location.every(x => x.application_location.status === this.location_statuses.STATUS_REJECTED)" outline color="primary"
                               @click.stop="finalizeRejectedApplication">
                            結果を確定
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <generic-dialog :dialog="finalizeConfirmDialog" :success="{ text: $t('common.ok'), action: this.confirmThanksDialogue}"
                        :description="$t('dialog.apply_final_confirmation')"
                        :title="'申請内容の確認'"></generic-dialog>

        <generic-dialog :dialog="rentedLocsDialogue" :success="{ text: $t('common.ok'), action: this.closeRentedDialogue}"
                        :description="this.dialogMessageForRentedLocs"
                        :title="'文言'"></generic-dialog>
        <generic-dialog :dialog="dialog" :success="{ text: $t('common.ok'), action: this.confirmApplication}" :fail="{ text: $t('common.cancel'), action: this.cancelApplication}"
                        :description="$t('dialog.apply_warning_content')"
                        :title="$t('dialog.apply_warning')"></generic-dialog>
        <generic-dialog :dialog="thanksDialog" :success="{ text: $t('common.ok'), action: this.saveApplication}"
                        :description="$t('dialog.apply_thank_you')"
                        :title="$t('dialog.apply_finished')"></generic-dialog>
        <generic-dialog :dialog="finalizeDialog" :success="{ text: $t('common.ok'), action: () => { this.finalizeDialog = false; this.$router.push('/location/borrow'); } }"
                        description="ロケーションの利用が開始されました"
                        title="開始されました"></generic-dialog>
        <generic-dialog :dialog="finalizeRejectedDialog" :success="{ text: $t('common.ok'), action: () => { this.finalizeRejectedDialog = false; this.$router.push('/applications'); } }"
                        description="ロケーションの利用が却下されました"
                        title="却下されました"></generic-dialog>
    </div>
</template>

<script>
    import CardLocation from "./cards/CardLocation";
    import Api, {ApiHelper} from "../api/Api";
    import GenericDialog from './GenericDialog';
    import {LOCATION_APPLICATION_STATUS} from "../utils/masterdata";

    export default {
        props: ['itemModel', 'mode', 'locationType'],
        mounted() {
            this.getApplis();
        },
        components: {
            GenericDialog,
            CardLocation
        },
        watch: {
            itemModel: function (newVal, oldVal) {
                this.item = newVal;
            }
        },
        data() {
            return {
                item: this.itemModel,
                applis: [],
                ownMode: this.mode,
                dialog: false,
                finalizeDialog: false,
                finalizeConfirmDialog:false,
                finalizeRejectedDialog: false,
                agreeTerms: false,
                thanksDialog: false,
                errors: [],
                menu: false,
                confirm: false,
                location_statuses: LOCATION_APPLICATION_STATUS,
                dialogMessageForRentedLocs:"",
                rentedLocsDialogue:false
            };
        },
        methods: {
            getApplis() {
                Api.get('/appli').then(response => {
                    this.applis = response.data;
                })
            },
            makeApplication() {
                this.confirm = false;
                if (this.ownMode) {
                    this.saveApplication();
                } else {
                    this.dialog = true;
                }
            },
            saveApplication() {
                const promises = [];
                this.item.location.filter(x => !x.use).forEach(x => promises.push(Api.delete(`/application/${this.item.id}/location/${x.id}`)));
                return Promise.all(promises).then(() => {
                    return Api.post(`/application/${this.item.id}/_confirm`).then(response => {
                        this.ownMode = false;

                        if (response.success) {
                            this.completeApplication();
                           // this.thanksDialog = true;
                        } else {
                            if (response.data.message) {
                                this.thanksDialog=false;
                                this.finalizeConfirmDialog=false;
                                alert(response.data.message);
                            } else {
                                alert('System error');
                            }
                        }
                    });
                });
            },
            confirmApplication() {
                let use_check=[];
                if(this.item.has_notifications!==0 && this.item.has_notifications){
                    use_check.push(this.item.has_notifications)
                }
                if(this.item.has_stamp_free!==0 && this.item.has_stamp_free){
                    use_check.push(this.item.has_stamp_free)
                }
                if(this.item.has_campaign!==0 && this.item.has_campaign){
                    use_check.push(this.item.has_campaign)
                }
                let loc_ids=JSON.parse(JSON.stringify(this.item.location)).map((x)=>{
                    return x.id
                });

                let loc_use=JSON.parse(JSON.stringify(this.item.location)).map((x)=>{
                    return {
                        use:(x.use==undefined)?false:x.use,
                        loc_id:x.id
                    };
                });
                const object = {
                    ...this.item,
                    loc_ids:loc_ids,
                    use_check:JSON.parse(JSON.stringify((use_check))),
                    accept_terms: 1.,
                    use:JSON.stringify(loc_use),

                };
                const url = ApiHelper.queryBuilder(`/application/${this.item.id}`, object);
                Api.put(url).then(response => {
                    this.finalizeConfirmDialog = false;
                    this.dialog = false;

                    if (response.success) {
                        this.ownMode = true;
                        this.confirm = true;
                        this.errors = [];
                        this.finalizeConfirmDialog=true;
                    } else {
                        this.ownMode = false;
                        if (response.data && response.data.errors) {
                            this.errors = response.data.errors;
                            if(this.errors.loc_ids[0].length>0)
                            {
                                this.dialogMessageForRentedLocs="";
                                this.errors.loc_ids[0].map((message)=>{

                                    this.dialogMessageForRentedLocs+="\n"+message;
                                })
//
                                this.dialogMessageForRentedLocs+="\nは既に選択されたアプリで利用されています。\n\n対象のロケーションを申請から外す、または希望利用開始日を現在の利用期間より未来日を設定して下さい。";
                               // console.log(this.errors.loc_ids[0])
                                this.rentedLocsDialogue=true;
                            }
                            this.thanksDialog=false;
                        } else {
                            alert('System error');
                        }
                    }
                });
            },


            confirmThanksDialogue(){
                this.thanksDialog=true;
            },
            finalizeConfirmation(){
                 this.dialog=true;
            },
            closeRentedDialogue(){
                this.rentedLocsDialogue=false;
                this.dialogMessageForRentedLocs="";
            },
            finalizeApplication() {
                Api.put(`/application/${this.item.id}?is_started=1`).then(response => {
                    this.finalizeDialog = true;
                })
            },
            finalizeRejectedApplication() {
                Api.put(`/application/${this.item.id}?deleted=1`).then(response => {
                    this.finalizeRejectedDialog = true;
                })
            },
            cancelApplication() {
                this.dialog = false;
                this.agreeTerms = false;
                this.ownMode = false;
            },
            completeApplication() {
                this.thanksDialog = false;
                this.$router.push('/applications');
            },
            removeLocation(index) {
                const locationId = this.item.location[index].id;
                const location = [...this.item.location];
                location.splice(index, 1);
                this.item.location = location;
                Api.delete(`/application/${this.item.id}/location/${locationId}`).then(() => {
                    if(this.item.location.length === 0) {
                        Api.delete(`/application/${this.item.id}`).then(() => {
                            this.$router.back();
                        });
                    }
                });
            },
        }
    };
</script>

<style>
</style>
