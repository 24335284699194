<template>
    <v-list-tile
            :key="item.id"
            @click=""
    >

        <v-list-tile-content>
            <v-layout row style="width: 100%">
                <v-flex xs8>
                    <v-list-tile-title>{{$t('fields.name')}}: {{ item.name }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{$t('beacons.uuid')}}: {{ item.uuid }}</v-list-tile-sub-title>
                </v-flex>
                <v-flex xs4>
                    <v-list-tile-sub-title>{{$t('major')}}: {{ item.major }}</v-list-tile-sub-title>
                    <v-list-tile-sub-title>{{$t('minor')}}: {{ item.minor }}</v-list-tile-sub-title>
                </v-flex>
            </v-layout>
        </v-list-tile-content>

        <v-list-tile-action>
            <v-checkbox v-model="curItem.use"></v-checkbox>
        </v-list-tile-action>
    </v-list-tile>
</template>
<script>

    export default {
        props: ["item"],

        methods: {},
        computed: {
            curItem: {
                get () { return this.item },
                set (value) {
                    var newItem = {...item, use: !item.use};
                    this.$emit('update:item', newItem)
                },
            },
        },
    };
</script>
