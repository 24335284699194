<template>
    <div v-if="item">
        <v-layout row wrap py-2>
            <v-flex xs12>
                <v-card>
                    <v-card-title>
                        <v-icon color="secondary">next_week</v-icon>
                        <span>&ensp;</span>
                        <h3>{{ $t('menu.ApplicationDetail') }}</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-flex>
                            <card-location v-for="(location, index) in item.location"
                                           :key="location.id"
                                           :item="location"
                                           :index="index"
                                           :application="item"
                                           :mode="ownMode"
                                           :type="locationType">
                            </card-location>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :label="$t('apply.applicant')"
                                :disabled="ownMode"
                                v-model="item.leaser.name"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :label="$t('apply.date_of_application')"
                                :disabled="ownMode"
                                v-model="item.created_at"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :label="$t('apply.application_for_use')"
                                v-model="item.appli.name"
                                :disabled="ownMode"
                                :error-messages="errors['appli_id']"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <label>利用用途</label>
                            <v-flex xs9>
                                <v-checkbox :disabled="ownMode" v-model="item.has_notifications" label="通知に使用したい"></v-checkbox>
                                <v-checkbox :disabled="ownMode" v-model="item.has_stamp_free" label="スタンプラリーに使用したい"></v-checkbox>
                                <v-checkbox :disabled="ownMode" v-model="item.has_campaign" label="キャンペーンに使用したい"></v-checkbox>
                            </v-flex>
                        </v-flex>
                        <v-flex>
                            <v-textarea
                                :label="$t('apply.other')"
                                :disabled="ownMode"
                                v-model="item.remarks"
                                :error-messages="errors['remarks']"
                            ></v-textarea>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :label="$t('apply.desired_start_date')"
                                :disabled="ownMode"
                                v-model="item.desired_start_date"
                                :error-messages="errors['desired_start_date']"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :label="$t('apply.usage_period')"
                                :disabled="ownMode"
                                v-model="item.usage_duration"
                                aria-placeholder="3"
                                :error-messages="errors['usage_duration']"
                            ></v-text-field>
                        </v-flex>
                        <v-flex v-if="item.location.filter(x => x.application_location.status === location_statuses.STATUS_REJECTED).length > 0">
                            <label>却下理由</label>
                            <v-flex xs9>
                                <v-checkbox :error-messages="test_error_2" v-model="item.rejection_reason_1" label="公序良俗に反する為"></v-checkbox>
                                <v-checkbox :error-messages="test_error_2" v-model="item.rejection_reason_2" label="政治活動もしくは選挙運動、またはこれらに関連する行為に利用する為"></v-checkbox>
                                <v-checkbox :error-messages="test_error" v-model="item.rejection_reason_3" label="宗教活動、またはこれに関連する行為に利用する為"></v-checkbox>
                            </v-flex>
                            <v-textarea
                                :label="$t('apply.other')"
                                v-model="item.rejection_reason"
                                :error-messages="errors['rejection_reason']"
                            ></v-textarea>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outline color="error" @click.stop="$router.go(-1)">{{ $t('common.cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outline color="primary" @click.stop="saveApplication">{{ $t('common.confirm') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import CardLocation from "./cards/CardLocation";
    import Api, {ApiHelper} from "../api/Api";
    import GenericDialog from './GenericDialog';
    import {getUserDetails} from "../utils/user";
    import {LOCATION_APPLICATION_STATUS} from "../utils/masterdata";

    export default {
        props: ['itemModel', 'mode', 'locationType'],
        mounted() {
            this.getApplis();
        },
        components: {
            GenericDialog,
            CardLocation
        },
        watch: {
            itemModel: function (newVal, oldVal) {
                this.item = newVal;
            }
        },
        data() {
            return {
                item: this.itemModel,
                applis: [],
                ownMode: this.mode,
                dialog: false,
                agreeTerms: false,
                thanksDialog: false,
                errors: [],
                location_statuses: LOCATION_APPLICATION_STATUS,
                test_error: null,
                test_error_2: null
            };
        },
        methods: {
            saveApplication() {
                const companyId = getUserDetails().company_id;
                const toSend = {
                    ...this.item,
                    rejection_reason_1: this.item.rejection_reason_1 ? 1 : 0,
                    rejection_reason_2: this.item.rejection_reason_2 ? 1 : 0,
                    rejection_reason_3: this.item.rejection_reason_3 ? 1 : 0
                };

                if(this.item.location.filter(x => x.application_location.status === this.location_statuses.STATUS_REJECTED).length > 0){
                    console.log(toSend.rejection_reason_1==0 && toSend.rejection_reason_2==0 && toSend.rejection_reason_3==0);
                    if(toSend.rejection_reason_1==0 && toSend.rejection_reason_2==0 && toSend.rejection_reason_3==0) {
                        this.test_error = "却下するロケーションがある場合は却下理由を入力してください";
                        this.test_error_2 = ' ';
                        return;
                    }
                }

                const promises = [];

                this.item.location.forEach(l => promises.push(Api.put(`/company/${companyId}/application/${this.item.id}/location/${l.id}?status=${l.application_location.status}`)));
                const isFinished = this.item.location.some(l => l.application_location.status === LOCATION_APPLICATION_STATUS.STATUS_PENDING) ? 0 : 1;

                return Promise.all(promises).then(responses => {
                    return Api.put(`/company/${companyId}/application/${this.item.id}?is_finished=${isFinished}`, toSend).then(response => {
                        if (response.success) {
                            this.$router.back()
                        } else {
                            if (response.data.errors) {
                                this.errors = response.data.errors;
                            } else {
                                if (response.data.message) {
                                    alert(response.data.message);
                                } else {
                                    alert('System error');
                                }
                            }
                        }

                    });
                });
            },
        }
    };
</script>

<style>
</style>
