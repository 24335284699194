<template>
    <div>
        <examination-detail :itemModel="item" :mode="true" locationType="examination"></examination-detail>
    </div>
</template>

<script>
    import ExaminationDetail from "../../components/ExaminationDetail";
    import Api from "../../api/Api";
    import {getUserDetails} from "../../utils/user";
    import {LOCATION_APPLICATION_STATUS} from "../../utils/masterdata";

    export default {
        components: {
            ExaminationDetail
        },
        data() {
            return {
                item: null
            };
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            onFetch() {
                const {company_id, application_id} = this.$route.params;
                const companyId = getUserDetails().company_id;
                Api.get(`/company/${companyId}/application/${application_id}?with[]=location&with[]=location.photo&with[]=leaser&with[]=appli`).then(response => {
                    const location = response.data.location.filter(l => l.application_location.status === LOCATION_APPLICATION_STATUS.STATUS_PENDING);
                    this.item = {...response.data, location};
                })
            },
        },
    };
</script>

<style>
</style>
