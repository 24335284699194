import axios from "axios";
import _env from "../env";

const prefix = _env.LMP_API + '/api';

export class ApiHelper {

    static url(path) {
        return `${prefix}${path}`;
    }

    static pathBuilder(path, params, withList) {
        path = params ? `${path}/${params}?` : `${path}?`;
        return withList.reduce((a, c) => `${a}with[]=${c}`, path);
    }

    static queryBuilder(path, params) {
        var paramStr = '';
        const alreadyHasParameters = path.includes('?');

        Object.keys(params).forEach(p => {
            if (params[p] !== null && params[p] !== '' && params[p] !== undefined) {
                if (paramStr !== '' || alreadyHasParameters) {
                    paramStr += '&'
                }
                if (params[p].constructor !== Boolean) {
                    paramStr += `${p}=${params[p]}`
                } else {
                    paramStr += `${p}=${params[p] ? 1 : 0}`
                }
            }
        });

        if (paramStr !== '') {
            if(alreadyHasParameters) {
                return path + paramStr;
            } else {
                return path + '?' + paramStr;
            }
        }
        return path;
    }

    static wrapper(response) {
        return {
            data: response.data,
            success: response.status ? response.status.toString()[0] === "2" : false
        }
    }
}

export default class Api {
    static get(path) {
        return axios
            .get(ApiHelper.url(path), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => ApiHelper.wrapper(response))
            .catch(error => ApiHelper.wrapper(error.response));
    }

    static post(path, body) {
        return axios
            .post(ApiHelper.url(path), body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => ApiHelper.wrapper(response))
            .catch(error => ApiHelper.wrapper(error.response));
    }

    static put(path, body) {
        return axios
            .put(ApiHelper.url(path), body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => ApiHelper.wrapper(response))
            .catch(error => ApiHelper.wrapper(error.response));
    }

    static delete(path, body) {
        return axios
            .delete(ApiHelper.url(path), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(response => ApiHelper.wrapper(response))
            .catch(error => ApiHelper.wrapper(error.response));
    }

    static login(body) {
        return axios
            .post(`${_env.LOCO_API}/lmp/login`, body)
            .then(response => ApiHelper.wrapper(response))
            .catch(error => ApiHelper.wrapper(error.response));
    }

}
