<template>
    <div>
        <v-layout row wrap>
            <v-flex xs12>
                <v-toolbar color="white" class="elevation-1">
                    <v-toolbar-title>
                        <span class="caption">{{ $t('common.total') }}</span>
                        {{ total }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
                <application-card v-for="item in items" :key="item.id" :item="item" type="examination" />
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import Api from "../../api/Api";
    import ApplicationCard from "../../components/ApplicationCard";
    import {getUserDetails} from "../../utils/user";

    export default {
        data() {
            return {
                items: [],
                total: 0,
                rowsPerPage: 10,
                pagination: 1,
            };
        },
        components: {ApplicationCard},
        computed: {
            pages() {
                return Math.ceil(this.total / this.rowsPerPage);
            }
        },
        watch: {
            pagination() {
                this.onFetch();
            },
            rowsPerPage() {
                this.pagination = 1;
                this.onFetch();
            }
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            onFetch() {
                const user = getUserDetails();
                const companyId = Number(user.company_id);
                Api.get(`/company/${companyId}/application?mark_examinations_read=1&with[]=location&with[]=appli&with[]=leaser&filter[]=examined(false)&with[]=applicationCompany`).then(response => {
                    this.items = response.data;
                    this.total = Object.keys(this.items).length;
                });
            }
        },
    };
</script>

<style>
</style>
