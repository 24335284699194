<template>

    <v-tabs
        v-model="active"
        color="primary"
        dark
        slider-color="white"
    >
        <v-tab ripple key="shiharai">支払い</v-tab>
        <v-tab ripple key="uketori">受け取り</v-tab>

        <v-tab-item key="shiharai">
            <v-card flat>
                <v-card-title>
                    月額合計：{{itemsPay.reduce((t, x) => t + sum(x.location.filter(location => location.company != null), 'usage_fee'), 0) | toCurrency}}
                </v-card-title>
                <v-card-text>
                    <v-layout row>
                        <v-flex xs12>
                            <div v-for="application in itemsPay" :key="application.id" style="padding-bottom: 16px;">
                                <v-card v-if="application.location.filter(location => location.company != null).length !== 0">
                                    <v-card-title>アプリケーション：{{application.name}}</v-card-title>
                                    <v-card-text>
                                        <div v-for="item in application.location.filter(location => location.company != null)" style="padding-bottom: 16px;">
                                        <v-card
                                            :key="item.id"
                                            class="list-card"
                                        >
                                            <card-location :item="item" :application="application" type="receipt"></card-location>
                                        </v-card>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-subheader>{{$t('common.subtotal')}}</v-subheader>
                                        <v-subheader style="font-weight: 100; font-family: 'Open Sans', sans-serif">{{sum(application.location.filter(location => location.company != null), 'usage_fee')}}円</v-subheader>
                                    </v-card-actions>
                                </v-card>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-tab-item>
        <v-tab-item key="uketori">
            <v-card flat>
                <v-card-title>
                    合計受領金額：{{itemsReceive.reduce((t, x) => t + x.usage_fee * (1-x.royalty) * (x.application.filter(item => item.leaser != null).length !== 0 ? x.application.filter(item => item.leaser).length : 0), 0) | toCurrency}}
                </v-card-title>
                <v-card-text>
                    <v-flex xs12 >
                        <div v-for="item in itemsReceive" :key="item.id" style="padding-bottom: 16px;">
                            <v-card
                                v-if="item.application.filter(item => item.leaser != null).length !== 0"
                                :key="item.id"
                                class="list-card"
                            >
                            <card-location  :item="item" :application="item.application" type="receiptReceive"></card-location>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                受領小計：{{(1-item.royalty) * item.usage_fee * (item.application ? item.application.length : 0)}}円
                            </v-card-actions>
                            </v-card>
                        </div>
                    </v-flex>
                </v-card-text>
            </v-card>
        </v-tab-item>
    </v-tabs>

</template>

<script>
    import CardLocation from "../../components/cards/CardLocation";
    import Api from "../../api/Api";
    import {sumArr} from "../../utils/maths";
    import {groupByAppli} from "../../utils/applications";

    export default {
        components: {
            CardLocation
        },
        mounted() {
            this.onFetch();
            const {tab} = this.$route.query;
            this.active = Number(tab);
        },
        data() {
            return {
                active: 0,
                total: 2,
                rowsPerPage: 1,
                pagination: 1,
                name: "山本",
                itemsPay: [],
                itemsReceive: [],
                sum: sumArr
            };
        },
        methods: {
            onFetch() {
                Promise.all([
                    Api.get('/application?with[]=location&with[]=location.company&with[]=location.photo&with[]=appli&filter[]=location.active()&filter[]=location.status(2)'),
                    Api.get('/location?with[]=application&with[]=application.leaser&with[]=photo&with[]=company&filter[]=application.active()')])
                    .then(responses => {
                        this.itemsPay = groupByAppli(responses[0].data.filter(x => x.is_started === 1));
                        this.itemsReceive = responses[1].data.filter(x => x.application && x.application.length > 0);
                    })
            }
        }
    };
</script>

<style scoped>
    .name {
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
    }
</style>
