<template>
  <div class="mx-0">
    <v-btn outline color="primary" @click.stop="dialog = true">{{$t('dialog.rejection_reason')}}</v-btn>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title>
          <v-icon>delete</v-icon>
          <span><strong>{{$t('dialog.rejection_reason')}}</strong></span>
        </v-card-title>
        <v-card-text>
          <v-layout row>
              <v-flex>
                  <label>却下理由</label>
                  <v-flex xs9>
                      <v-checkbox disabled v-model="item.rejection_reason_1" label="公序良俗に反する為"></v-checkbox>
                      <v-checkbox disabled v-model="item.rejection_reason_2" label="政治活動もしくは選挙運動、またはこれらに関連する行為に利用する為"></v-checkbox>
                      <v-checkbox disabled v-model="item.rejection_reason_3" label="宗教活動、またはこれに関連する行為に利用する為"></v-checkbox>
                  </v-flex>
                  <v-textarea
                      disabled
                      :label="$t('apply.other')"
                      v-model="item.rejection_reason"
                  ></v-textarea>
              </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ["item"],
    data() {
      return {
        dialog: false,
      };
    },
  };
</script>

<style>
</style>
