<template>
    <div>
        <application-detail :itemModel="item" :mode="false" locationType="application"></application-detail>
    </div>
</template>

<script>
    import ApplicationDetail from "../../components/ApplicationDetail";
    import Api from "../../api/Api";

    export default {
        components: {
            ApplicationDetail
        },
        data() {
            return {
                item: null
            };
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            onFetch() {
                const {application_id} = this.$route.params;
                Api.get(`/application/${application_id}?with[]=location&with[]=location.company&with[]=location.photo&filter[]=draft()`).then(response => {
                    this.item = response.data;
                })
            },
        },
    };
</script>

<style>
</style>
