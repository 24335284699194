<template>
    <div class="mx-0">
        <v-dialog v-model="dialog" persistent max-width="700" scrollable>
            <v-card>
                <v-card-title>
                    <v-icon>add</v-icon>
                    <span><strong>{{title}}</strong></span>
                </v-card-title>
                <v-card-text style="height: 200px;">
                    <v-form>
                        <v-container>
                            <v-layout row wrap align-center class="mb-2">
                                <v-flex xs12>
                                    <p v-if="type === 'forbidden'" style="overflow: auto; font-family: SFMono-Regular,monospace;">{{ description }}</p>
                                    <pre v-else>{{ description }}</pre>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="fail" flat color="secondary" @click.stop="fail.action">{{ fail.text }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="success" flat color="primary" @click.stop="success.action">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: ["dialog", "success", "fail", "description", "title", "type"]
    };
</script>

<style>
</style>
