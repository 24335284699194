<template>
    <div>
        <v-toolbar color="white" class="elevation-1">
            <v-toolbar-title>
                <span class="caption">{{$t('menu.Users')}}</span>
            </v-toolbar-title>
        </v-toolbar>
        <v-layout v-if="item" row>
            <v-flex xs12>
                <v-card
                    :key="item.id"
                    class="list-card"
                >
                    <card-location :item="item" :application="null" type="users"></card-location>
                </v-card>
            </v-flex>
        </v-layout>
        利用アプリケーション

        <v-layout v-if="item && item.application" row>
            <v-flex xs12>
                <v-card
                    v-for="app in item.application" :key="app.id"
                    class="list-card"
                >
                    <card-user :item="app"></card-user>
                </v-card>
            </v-flex>
        </v-layout>

        <div align="right">
            <v-btn color="success" dark @click="$router.go(-1)">戻る</v-btn>
        </div>
    </div>
</template>

<script>
    import CardLocation from "../../components/cards/CardLocation";
    import CardUser from "../../components/cards/CardUser";
    import Api from "../../api/Api";

    export default {
        components: {
            CardLocation, CardUser
        },
        mounted() {
            this.onFetch();
        },
        data() {
            return {
                item: null
            };
        },
        methods: {
            onFetch() {
                const {location_id} = this.$route.params;

                Api.get(`/location/${location_id}?with[]=application&with[]=photo&with[]=application.leaser&with[]=application.appli&filter[]=application.active()`)
                    .then(response => {
                        this.item = response.data;
                    });
            }
        }
    };
</script>

<style scoped></style>
