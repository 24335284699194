<template>
    <div style="padding-bottom:16px;">
    <v-card :to="type === 'examination' ? `${$route.path}/${item.company_id}/${item.id}` : `${$route.path}/${item.id}`" class="list-card">
        <v-container fluid>
            <v-layout justify-space-between row>
                <v-flex xs3>
                    <div>{{$t('apply.target_application')}}: {{item.appli.name}}</div>
                    <div v-if="type === 'examination'">{{$t('apply.applicant')}}: {{item.leaser.name}}</div>
                    <div v-else-if="type === 'application'">{{$t('apply.owner')}}: {{[...item.location.map(location => location.company.name)].join(', ')}}</div>
                    <div>{{$t('apply.date_of_application')}}: {{item.created_at}}</div>
                </v-flex>
                <v-flex xs3 v-if="item.location">
                    <div>{{$t('common.location')}}</div>
                    <div>{{item.location.map(x => x.name).slice(0, 2).join(', ')}}{{item.location.length > 2 ? ' …' : ''}}</div>
                    <div>{{$t('common.sum')}}：{{item.location.length}}{{$t('suffix.item')}}</div>
                </v-flex>
                <v-flex xs6 style="text-align: end;">
                    <v-layout v-if="type === 'application' && item.location" justify-space-between row>
                        <v-flex xs4 style="text-align: end">
                            Status
                        </v-flex>
                        <v-flex xs4>
                            <div>{{$t('shared.status.pending')}}</div>
                            <div>{{$t('shared.status.approved')}}</div>
                            <div>{{$t('shared.status.rejected')}}</div>
                        </v-flex>
                        <v-flex xs2>
                            <div>:</div>
                            <div>:</div>
                            <div>:</div>
                        </v-flex>
                        <v-flex xs2 >
                            <div>{{item.location.filter(x => x.application_location.status == statuses.STATUS_PENDING).length}}</div>
                            <div>{{item.location.filter(x => x.application_location.status == statuses.STATUS_APPROVED).length}}</div>
                            <div>{{item.location.filter(x => x.application_location.status == statuses.STATUS_REJECTED).length}}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout v-else-if="type === 'examination'" justify-space-between row>
                        <v-flex xs6 style="text-align: end">
                            Status
                        </v-flex>
                        <v-flex xs6>
                            <v-chip :color="exceeded ? 'red' : 'green'" text-color="white">{{$t(exceeded ? 'apply.exceeded_period' : 'apply.in_progress')}}</v-chip>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
    </div>
</template>
<script>
    import {LOCATION_APPLICATION_STATUS} from "../utils/masterdata";

    export default {
        name: 'application-card',
        props: ["item", "type"],
        mounted() {
            this.exceeded = Date.parse(this.item.created_at) + 5 * 3600 * 24 * 1000 < Date.now();
        },
        data() {
            return {
                statuses: LOCATION_APPLICATION_STATUS,
                exceeded: false
            }
        }
    }
</script>
