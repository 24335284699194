<template>
    <v-container fluid fill-height>
        <v-layout row wrap>
            <v-flex xs12>
                <h3>レンタル申請状況</h3>
                <v-list>
                    <v-list-tile>
                        <v-list-tile-title>アプリケーション名</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile v-for="app in applicationsInProgress" :key="app.id">
                        <v-list-tile-title>{{app.appli.name}}</v-list-tile-title>
                        <v-list-tile-sub-title v-if="app.location"><a @click.stop="$router.push('/applications/'+app.id)">{{app.location.filter(x => x.application_location.status === 0).length > 0 ? "申請中" : "審査結果有り"}}</a></v-list-tile-sub-title>
                    </v-list-tile>
                </v-list>
            </v-flex>
            <v-flex xs12>
                <h3>借りているロケーション</h3>
                <a @click.stop="$router.push('/location/borrow')">{{this.applications.reduce((sum, x) => sum + (x.location.filter(location => location.company != null).length !== 0 ? x.location.filter(location => location.company != null).length : 0), 0)}}件借りています</a>
            </v-flex>
            <v-flex xs12>
                <h3>貸しているロケーション</h3>
                <a @click.stop="$router.push('/location/rent')">{{this.locations.length}}件貸しています</a>
            </v-flex>
            <v-flex xs12>
                <h3>支払い金額</h3>
                <a @click.stop="$router.push('/receipts?tab=0')">{{this.applications.reduce((sum, x) => sum + x.location && x.location.filter(location => location.company != null).reduce((t,s) => t+s.usage_fee, sum), 0) | toCurrency}}</a>
            </v-flex>
            <v-flex xs12>
                <h3>受け取り金額</h3>

                <a @click.stop="$router.push('/receipts?tab=1')">{{this.locations.reduce((t,x) => t+x.usage_fee * (1-x.royalty) * (x.application ? x.application.length : 0),0) | toCurrency}}</a>
            </v-flex>
            <v-flex xs12>
                <v-card>
                    <v-card-title><h3>お知らせ</h3></v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-tile v-for="(notification, i) in notifications" :key="notification.id" @click="toggleDialog(i)">
                                <v-list-tile-title><a>{{notification.title}}</a></v-list-tile-title>
                                <v-list-tile-sub-title>{{notification.created_at}}</v-list-tile-sub-title>
                            </v-list-tile>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-flex>
            <generic-dialog v-if="currentNotification !== null" :dialog="true" :success="{ text: $t('common.ok'), action: () => this.toggleDialog(null)}"
                            :description="notifications[currentNotification].contents" :title="notifications[currentNotification].title"></generic-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Api from "../api/Api";
    import {sumArr} from "../utils/maths";
    import GenericDialog from '../components/GenericDialog';

    export default {
        components: {GenericDialog},
        mounted() {
            const promises = [
                Api.get("/application?with[]=location&with[]=location.company&with[]=examination&filter[]=location.active()&filter[]=location.status(2)&filter[]=started(true)"),
                Api.get("/application?with[]=location&with[]=examination&with[]=appli&filter[]=started(false)"),
                Api.get("/location?with[]=application&with[]=company&filter[]=application.active()"),
                Api.get("/notification"),
            ];
            Promise.all(promises).then(responseArray => {
                this.applications = responseArray[0].data;
                this.applicationsInProgress = responseArray[1].data;
                this.locations = responseArray[2].data.filter(location => location.company != null);
                this.notifications = responseArray[3].data.splice(0, 10);
            })
        },
        methods: {
            toggleDialog(id = null) {
                this.currentNotification = id;
            }
        },
        data() {
            return {
                applications: [],
                applicationsInProgress: [],
                locations: [],
                notifications: [],
                currentNotification: null,
                sum: sumArr
            }
        },

    };
</script>

