export default (
    process.env.NODE_ENV === 'development'?
    {
        "ENV":"development",
        "LMP_API":"//api.lmp.localhost:8080",
        "LOCO_API":"//api.loco.localhost:8083",
    }
    :(
    document.location.host.indexOf('stg')===-1?
    {
        "ENV":"production",
        "LMP_API":"//api-share.bc-loco.jp",
        "LOCO_API":"//api.bc-loco.jp",
    }:{
        "ENV":"staging",
        "LMP_API":"//stg.api-share.bc-loco.jp",
        "LOCO_API":"//stg.api.bc-loco.jp",
    })
)