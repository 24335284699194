import jwtDecode from "jwt-decode";

export default class Auth {
    // Should be called from router to allow redirection with next function
    static checkOrRedirectLogin = (to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (!this.checkTokenExpirationMiddleware()) {
                next({ name: "login" });
            } else {
                next(); // go to wherever I'm going
            }
        } else {
            next(); // does not require auth, make sure to always call next()!
        }
    };

    static checkTokenExpirationMiddleware() {
        const token = localStorage.getItem("token");
        if (!token || jwtDecode(token).exp < Date.now() / 1000) {
            localStorage.clear();
            return false;
        }
        return true;
    }
}
