<template>
    <div>
        <v-layout row wrap>
            <v-flex>
                <v-toolbar color="white" class="elevation-1">
                    <v-toolbar-title>
                        <span class="caption">{{ $t("common.total") }}</span>
                        {{ total }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="$router.push('/location/search')">新しく借りる</v-btn>
                </v-toolbar>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
                <div v-for="application in items" :key="application.id" style="padding-bottom: 16px;">
                    <v-card v-for="item in application.location.filter(location => location.company != null)">
                        <v-card-title>アプリケーション：{{application.appli.name}}</v-card-title>
                        <v-card-text>
                            <v-card
                                :key="item.id"
                                class="list-card"
                                :to="`${$route.path}/${application.id}/${item.id}`"
                            >
                                <card-location :item="item" :application="application" type="borrow"></card-location>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import CardLocation from "../../components/cards/CardLocation";
    import Api from "../../api/Api";
    import {groupByAppli} from "../../utils/applications";

    export default {
        components: {
            CardLocation
        },
        mounted() {
            this.onFetch();
        },
        watch: {
            pagination() {
                this.onFetch();
            },
            rowsPerPage() {
                this.pagination = 1;
                this.onFetch();
            }
        },
        data() {
            return {
                total: 0,
                rowsPerPage: 30,
                pagination: 1,
                items: []
            };
        },
        computed: {
            pages() {
                return Math.ceil(this.total / this.rowsPerPage);
            }
        },
        methods: {
            onFetch() {
                Api
                    .get('/application?with[]=location&with[]=location.company&with[]=location.photo&with[]=appli&filter[]=location.active(all)&filter[]=location.status(2)&filter[]=started(true)', {
                        params: Object.assign(
                            {
                                per_page: this.rowsPerPage,
                                page: this.pagination
                            },
                        )
                    })
                    .then(response => {
                        this.items = response.data;
                        this.total = this.items.reduce((sum, x) => sum + (x.location ? x.location.filter(y => y.company != null).length : 0), 0)
                    });
            }
        }
    };
</script>

<style scoped></style>
