<template>
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="`${width}px`"
    :height="`${height}px`"
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
  >
    <path
      fill="#F06292"
      d="M77.482,74.051c7.151-7.855,11.521-18.297,11.521-29.738c0-24.48-19.927-44.312-44.492-44.312
			c-18.194,0-33.832,10.858-40.737,26.444c10.688-4.324,22.344-6.602,34.516-6.602c3.38,0,6.113,2.723,6.113,6.094
			c0,3.355-2.733,6.078-6.113,6.078c-13.815,0-26.862,3.354-38.206,9.684C0.038,42.562,0,43.433,0,44.312
			c0,2.895,0.293,5.727,0.81,8.449c10.887-6.414,23.944-9.859,37.979-9.859c3.091,0,5.613,2.488,5.613,5.574
			c0,3.104-2.522,5.602-5.613,5.602c-13.184,0-24.922,3.517-34.288,9.652c1.555,3.167,3.478,6.132,5.715,8.82
			c8.069-5.246,18.09-8.306,29.051-8.306c2.837,0,5.138,2.297,5.138,5.11s-2.301,5.102-5.138,5.102
			c-8.465,0-16.137,2.125-22.375,5.816c5.095,5.715,12.548,13.809,18.431,20.137c4.328,4.648,4.388,7.191,1.086,11.484
			c-1.405,1.828-2.336,4.043-2.336,6.521c0,5.711,4.681,10.355,10.44,10.355c5.755,0,10.431-4.646,10.431-10.355
			c0-2.479-0.935-4.692-2.34-6.521c-3.302-4.293-3.238-6.836,1.077-11.484C62.465,90.948,74.771,77.566,77.482,74.051z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: { type: Number, default: 437 },
    height: { type: Number, default: 128 }
  }
};
</script>