<template>
    <div>
        <v-layout row wrap py-2>
            <v-flex xs12>
                <v-card>
                    <v-card-title>
                        <v-icon color="secondary">chat</v-icon>
                        <span>&ensp;</span>
                        <h2>{{item.subject}}</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-list id="messageList" two-line class="scroll-y" style="max-height: 400px">
                            <template v-for="(message, index) in item.message">
                                <v-list-tile :key="message.id">
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{ getSender(message).name }}</v-list-tile-title>
                                        <v-list-tile-sub-title class="text--primary">{{ message.contents }}</v-list-tile-sub-title>
                                    </v-list-tile-content>

                                    <v-list-tile-action>
                                        <v-list-tile-action-text>{{ message.created_at }}</v-list-tile-action-text>
                                    </v-list-tile-action>

                                </v-list-tile>
                                <v-divider></v-divider>
                            </template>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-flex>
                            <v-textarea v-model="newMessage" :error-messages="errors['contents']"></v-textarea>
                            <br/>
                            <v-layout row>
                                <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.close') }}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn outline color="primary" @click.stop="sendMessage">{{ $t('common.send') }}</v-btn>
                            </v-layout>
                        </v-flex>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>`

<script>

    import Api from "../../api/Api";
    import {getUserDetails} from "../../utils/user";

    export default {
        data() {
            return {
                item: {},
                newMessage: '',
                errors: []
            };
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            myCompanyId() {
                const user = getUserDetails();
                return Number(user.company_id);
            },
            onFetch() {
                const {inquiry_id} = this.$route.params;
                Api.get(`/inquiry/${inquiry_id}?with[]=message&with[]=company&mark_read=1`).then(response => {
                    this.item = response.data;
                    this.scrollToBottom()
                })
            },
            getSender(message) {
                return this.item.company.find(x => x.id === Number(message.company_id))
            },
            scrollToBottom() {
                return this.$nextTick(() => {
                    const container = this.$el.querySelector("#messageList");
                    container.scrollTop = container.scrollHeight;
                });
            },
            getCurrentTimestamp() {
                function appendLeadingZeroes(n) {
                    if (n <= 9) {
                        return "0" + n;
                    }
                    return n
                }

                let current_datetime = new Date();
                return current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate()) + " " + appendLeadingZeroes(current_datetime.getHours()) + ":" + appendLeadingZeroes(current_datetime.getMinutes()) + ":" + appendLeadingZeroes(current_datetime.getSeconds())
            },
            sendMessage() {
                const {inquiry_id} = this.$route.params;
                Api.post(`/inquiry/${inquiry_id}/message`, {contents: this.newMessage}).then(response => {
                    if(response.success) {
                        this.item.message.push({contents: this.newMessage, company_id: this.myCompanyId(), created_at: this.getCurrentTimestamp()});
                        this.newMessage = '';
                        this.scrollToBottom();
                    } else {
                        this.errors = response.data.errors;
                    }
                });
            }
        },
    };
</script>

<style>
</style>
