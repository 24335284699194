<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer :value="open" app stateless>
        <v-list shaped nav>
            <v-list-tile>
                <div class="headline">Loco Share</div>
            </v-list-tile>
            <v-list-tile class="side-menu_link" :to="item.path" v-for="item in items" :key="item.name">
                <v-list-tile-action>

                    <v-badge :color="item.notifications && item.notifications > 0 ? 'error':'primary'">
                        <template v-if="item.notifications !== null && item.notifications>0" v-slot:badge>{{item.notifications}}</template>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-badge>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title>{{$t(`menu.${item.name}`)}}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-list-tile class="side-menu_link" >
                <v-list-tile-action>
                    <v-badge color="primary">
                        <v-icon>dashboard</v-icon>
                    </v-badge>
                </v-list-tile-action>
                <v-list-tile-content>

                    <v-list-tile-title>
                        <form :action="formUrl" method="post">
                            <input type="hidden" name="Authorization" :value="token"/>
                            <input type="hidden" name="company_name" :value="company.name"/>
                            <button type="submit">{{$t(`menu.Dashboard`)}}</button>
                        </form>
                    </v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-list-tile class="side-menu_link" href="mailto:loco_support@beacrew.jp">
                <v-list-tile-action>
                    <v-badge color="primary">
                        <v-icon>contact_support</v-icon>
                    </v-badge>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title>{{$t(`menu.Support`)}}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import Api from "../api/Api";
    import _env from "../env";

    export default {
        props: ["open"],
        data() {
            return {
                items: [
                    {name: "Home", icon: "home", path: "/home", notifications: null},
                    {
                        name: "Locations to borrow",
                        icon: "local_offer",
                        path: "/location/borrow", notifications: null
                    },
                    {
                        name: "Locations to rent",
                        icon: "location_on",
                        path: "/location/rent", notifications: null
                    },
                    {
                        name: "Applications",
                        icon: "settings_applications",
                        path: "/applications", notifications: null
                    },
                    {name: "Examination", icon: "check_circle", path: "/examinations", notifications: null},
                    {name: "Inquiries", icon: "question_answer", path: "/inquiries", notifications: null},
                    {name: "Fees", icon: "attach_money", path: "/receipts", notifications: null},
                ],
                company: {name: ''},
                formUrl: `${_env.LOCO_API}/lmp/lmpToken`,
                token: localStorage.getItem('token'),
                timer: 0
            };
        },
        mounted() {
            this.updateNotifications();
            this.timer = setInterval(this.updateNotifications, 60000);
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            updateNotifications() {
                Api.get("/auth/company").then(response => {
                    this.items[3].notifications = response.data.rent_notices;
                    this.items[4].notifications = response.data.lend_notices;
                    this.items[5].notifications = response.data.inquiry_notices;
                    this.company = response.data;
                })
            }
        }
    };
</script>

<style scoped>
    .side-menu_link:hover {
        text-decoration: none;
    }
</style>
