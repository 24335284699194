<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="login"
                    label="Mail"
                    name="login"
                    prepend-icon="person"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="onLogin">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      <span>Invalid Credentials</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>
<script>
import Api from "../api/Api";

export default {
  data() {
    return {
      snackbar: false,
      color: "secondary",
      mode: "",
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: "right",
      y: "top",
      login: "",
      password: ""
    };
  },
  methods: {
    onLogin() {
      Api.login({ email: this.login, password: this.password, totp: "" }).then(
        ({ data }) => {
          if (data.code === 400) {
            this.snackbar = true;
          } else if (data.code === 200) {
            localStorage.setItem("token", data.payload.token);
            Api.get('/auth/user').then(response => {
              localStorage.setItem("user", JSON.stringify(response.data));
              if(response.data.tos == "1") {
                this.$router.push("/home");
              } else {
                this.$router.push("/tos");
              }
            });
          }
        }
      );
    }
  }
};
</script>

