<template>
    <div>
        <v-layout row wrap>
            <v-flex xs12>
                <v-toolbar color="white" class="elevation-1">
                    <v-toolbar-title>
                        <span class="caption">{{ $t("common.total") }}</span>
                        {{ total }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn to="/location/rent/new">新しく貸す</v-btn>
                </v-toolbar>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
                <div v-for="item in items" style="padding-bottom: 16px;">
                    <v-card
                        :key="item.id"
                        class="list-card"
                        :to="`${$route.path}/${item.id}`"
                    >
                        <card-location :item="item" :application="item.application ? item.application[0] : null" type="rental"></card-location>
                 </v-card>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import CardLocation from "../../components/cards/CardLocation";
    import Api from "../../api/Api";

    export default {
        components: {
            CardLocation
        },
        mounted() {
            this.onFetch();
        },
        watch: {
            pagination() {
                this.onFetch();
            },
            rowsPerPage() {
                this.pagination = 1;
                this.onFetch();
            }
        },
        data() {
            return {
                total: 0,
                rowsPerPage: 30,
                pagination: 1,
                items: []
            };
        },
        computed: {
            pages() {
                return Math.ceil(this.total / this.rowsPerPage);
            }
        },
        methods: {
            onFetch() {
                Api
                    .get('/location?with[]=photo&with[]=application&with[]=application.leaser&with[]=company&filter[]=application.active()', {
                        params: Object.assign(
                            {
                                per_page: this.rowsPerPage,
                                page: this.pagination
                            },
                        )
                    })
                    .then(response => {
                        this.items = response.data;
                        this.total = response.data.length;
                    });
            }
        }
    };
</script>

<style scoped></style>
