<template>
    <div>


        <v-card v-if="item" class="my-2">

            <v-card-title primary-title>
                <v-layout row justify-space-between dense>
                    <v-flex></v-flex>
                </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <LocationDetail :itemModel="item" :mode="false" :submit-function="submit" :errors="errors" type="rentNew" create="true"/>
            <generic-dialog :dialog="confirmDialog" title="確認" description="この内容でロケーションを作成します"
                            :success="{ text: $t('common.confirm'), action: this.saveDraft}"
                            :fail="{ text: $t('common.cancel'), action: () => this.confirmDialog = false}"></generic-dialog>
        </v-card>
    </div>

</template>

<script>

    import LocationDetail from "../../components/LocationDetail";
    import Api from "../../api/Api";
    import GenericDialog from "../../components/GenericDialog";

    export default {
        components: {GenericDialog, LocationDetail},
        data() {
            return {
                item: {
                    city: null,
                    beacon: [],
                    description: "",
                    has_airport: 0,
                    has_amusement: 0,
                    has_boat: 0,
                    has_bus: 0,
                    has_dept_store: 0,
                    has_food_drink: 0,
                    has_grocery: 0,
                    has_hall_stadium: 0,
                    has_parking: 0,
                    has_railway: 0,
                    has_road: 0,
                    has_supermarket: 0,
                    id: null,
                    name: "",
                    photo: [],
                    prefecture: null,
                    rules: "",
                    usage_fee: 0
                },
                errors: [],
                confirmDialog: false
            };
        },
        mounted() {
            this.createDraft();
        },
        methods: {
            submit(data) {
                Api.put(`/location/${this.item.id}`, data).then(response => {
                    if (response.success) {
                        this.saveDraft()
                    } else {
                        if (response.data && response.data.errors) {
                            this.errors = response.data.errors;
                        } else {
                            alert('System error');
                        }
                    }
                });
            },
            saveDraft() {
                this.confirmDialog = false;
                Api.post(`/location/${this.item.id}/_confirm`).then(response => {
                    this.$router.go(-1);
                });
            },
            createDraft() {
                Api.post(`/location`).then(response => {
                    this.item = {...this.item, id: response.data.id};
                });
            }
        },
    };
</script>

