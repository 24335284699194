<template>
  <div class="mx-0">
    <v-btn outline color="error" @click.stop="dialog = true" :disabled="disabled">{{$t('dialog.beacon_delete')}}</v-btn>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title>
          <v-icon>delete</v-icon>
          <span><strong>{{$t('dialog.beacon_delete')}}</strong></span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-layout row>
                <v-spacer></v-spacer>
                <v-checkbox @change="checkAll" label="ALL" v-model="allCheck" ></v-checkbox>
              </v-layout>
            </v-container>
          </v-form>
          <v-layout row>
            <v-flex xs12>
              <v-list>
                <card-beacon v-for="beacon in beacons" :key="beacon.id" :item="beacon"></card-beacon>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="error" :loading="loading" @click.stop="onDelete">{{ $t('common.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import CardBeacon from './cards/CardBeacon'
  import Api, {ApiHelper} from "../api/Api";

  export default {
    props: ["disabled", "loading", "deleteBeacons", "curBeacons"],
    components: {
      CardBeacon
    },
    watch: {
      curBeacons: function (newValue, oldValue) {
          this.beacons = newValue.map(b => ({...b, use: false}));
      }
    },
    data() {
      return {
        dialog: false,
        beacons: this.curBeacons.map(b => ({...b, use: false})),
        allCheck: false
      };
    },
    methods: {
      checkAll() {
        this.beacons = this.beacons.map(b => ({...b, use: this.allCheck}));
      },
      onDelete() {
        this.deleteBeacons(this.beacons.filter(x => x.use === true));
        this.dialog = false;
      }
    }
  };
</script>

<style>
</style>
