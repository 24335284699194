<!--<template>-->
<!--  <v-app id="inspire">-->
<!--    <side-menu :open="drawer"></side-menu>-->
<!--    <v-app-bar color="primary" dark app>-->
<!--      <v-app-bar-nav-icon class="layout-home_btn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
<!--      <v-toolbar-title>LMP</v-toolbar-title>-->
<!--    </v-app-bar>-->
<!--    <v-content>-->
<!--      <v-container fluid fill-height>-->
<!--        <router-view></router-view>-->
<!--      </v-container>-->
<!--    </v-content>-->
<!--    <v-footer color="primary" app>-->
<!--      <span class="white&#45;&#45;text">&copy; 2019</span>-->
<!--    </v-footer>-->
<!--  </v-app>-->
<!--</template>-->
<!--<script>-->
<!--import AppLogo from "@components/logo";-->
<!--import SideMenu from "@layouts/side-menu";-->
<!--export default {-->
<!--  components: {-->
<!--    AppLogo,-->
<!--    SideMenu-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      drawer: null-->
<!--    };-->
<!--  }-->
<!--};-->
<!--</script>-->
<!--<style scoped>-->
<!--.layout-home_btn:focus {-->
<!--  outline: none;-->
<!--}-->
<!--</style>-->

<template>
    <v-app id="inspire">
        <side-menu :open="drawer"></side-menu>
        <v-toolbar app fixed dark color="primary">
            <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
            <v-toolbar-title><strong>{{ $route.meta.page }}</strong></v-toolbar-title>
<!--            <v-toolbar-title><strong>{{ $t(`menu.${$route.meta.page}`) }}</strong></v-toolbar-title>-->
            <v-spacer></v-spacer>
            <v-toolbar-items>
<!--                <v-menu attach offset-y left min-width="64px" class="">-->
<!--                    <v-btn flat slot="activator">-->
<!--                        <v-avatar tile size="32px">-->
<!--                            <img :src="`https://countryflags.io/${transform($i18n.locale)}/flat/32.png`" alt="32px">-->
<!--                        </v-avatar>-->
<!--                    </v-btn>-->
<!--                    <v-list dense>-->
<!--                        <v-list-tile avatar v-for="(item, i) in items" :key="i" @click="setLanguage(item.locale)">-->
<!--                            <v-list-tile-avatar tile size="24px">-->
<!--                                <img :src="`https://countryflags.io/${transform(item.locale)}/flat/24.png`" alt="24px">-->
<!--                            </v-list-tile-avatar>-->
<!--                            <v-list-tile-content>-->
<!--                                <v-list-tile-title>{{ $t(`language.${item.language}`) }}</v-list-tile-title>-->
<!--                            </v-list-tile-content>-->
<!--                        </v-list-tile>-->
<!--                    </v-list>-->
<!--                </v-menu>-->
            </v-toolbar-items>
            <v-menu
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="200"
            >
                <v-btn icon slot="activator">
                    <v-icon>more_vert</v-icon>
                </v-btn>
                <v-list>
                    <v-list-tile>
                        <v-list-tile-avatar>
                            <!--              <img :src="user.image" alt="" v-if="user.image">-->
                            <img src="../../public/user.png" alt="">  <!--v-else>-->
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                            <v-list-tile-title>{{this.user.name}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{this.user.email}}</v-list-tile-sub-title>
                            <!--              <v-list-tile-title>{{ user.name }}</v-list-tile-title>-->
                            <!--              <v-list-tile-sub-title>{{ user.email }}</v-list-tile-sub-title>-->
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile @click.stop="onLogout">
                        <v-list-tile-content>{{ $t('logout') }}</v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-menu>
        </v-toolbar>
        <v-content>
            <v-container fluid grid-list-lg>
                <router-view></router-view>
            </v-container>
        </v-content>
        <v-footer class="pa-3">
            <v-spacer></v-spacer>
            <div>&copy; {{ $t('copyright') }}</div>
        </v-footer>
    </v-app>
</template>

<script>
    import AppLogo from "../components/Logo";
    import SideMenu from "../layouts/side-menu";
    import {getUserDetails} from '../utils/user';

    export default {
        components: {
            AppLogo,
            SideMenu
        },
        mounted() {
            const user = getUserDetails();
            if(user) {
                this.user = user;
            }
        },
        data() {
            return {
                drawer: true,
                items: [
                    {language: "English", locale: "en"},
                    {language: "Japanese", locale: "ja"}
                ],
                user: { name: '', email: ''}
            };
        },
        methods: {
            goHome() {
                this.$router.push("/home");
            },
            onLogout() {
                localStorage.clear();
                this.$router.push("/login");
            },
            transform(locale) {
                switch (locale) {
                    case "en":
                        return "us";
                    case "ja":
                        return "jp";
                }
            },
            setLanguage(locale) {
                this.$i18n.locale = locale;
            }
        }
    };
</script>

<style>
    .default-template {
        background: #e0e0e0 !important;
    }

    .navigation-logo {
        padding: 16px 0;
    }

    .navigation-logo img {
        width: 230px;
    }
</style>

