<template>
  <div>
    <v-tooltip bottom>
      <v-btn outline color="secondary" slot="activator" @click.stop="onOpen">検索</v-btn>
      <span>{{ $t("common.search_operations") }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" persistent max-width="650">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">search</v-icon>
          <span>&ensp;</span>
          <h4>{{ $t("common.search_operations") }}</h4>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t("location_search.prefectures") }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-select
                :items="prefectures"
                v-model="search.prefecture"
                v-on:change="getCities"
              >
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t("location_search.city") }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-select
                      :items="cities"
                      v-model="search.city"
              >
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout row class="pb-4">
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t("location_search.free_word") }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                      v-model="search.query"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-layout column>
            <v-flex xs12 class=" grey--text pt-2 pr-3">
              <strong>{{ $t("facilities.commercial") }}</strong>
            </v-flex>
            <v-container fluid container grid-list-xs>
              <v-layout row wrap>
                <v-flex
                  xs4
                  v-for="(v, facility) in facilities_search.commercial"
                  :key="facility"
                >
                  <v-checkbox
                    :label="$t(`facilities.${facility}`)"
                    v-model="facilities_search.commercial[facility]"
                    class="ma-0"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
          <v-layout column>
            <v-flex xs12 class=" grey--text pt-2 pr-3">
              <strong>{{ $t("facilities.transport") }}</strong>
            </v-flex>
            <v-container fluid container grid-list-xs>
              <v-layout row wrap>
                <v-flex
                  xs4
                  v-for="(v, facility) in facilities_search.transport"
                  :key="facility"
                >
                  <v-checkbox
                    :label="$t(`facilities.${facility}`)"
                    v-model="facilities_search.transport[facility]"
                    class="ma-0"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClear">{{
            $t("common.clear")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.stop="onSearch">{{
            $t("common.search")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {getTypeObject} from "../../utils/facilities";
import {CITIES, PREFECTURES} from "../../utils/masterdata";

export default {
  props: ['onFetch'],
  data() {
    return {
      dialog: false,
      search: {
        prefecture: null,
        city: null,
        query: ''
      },
      copy: {},
      prefectures: [{text: 'ALL', value: null},...Object.values(PREFECTURES)],
      cities: [],
      facilities_search: {}
    };
  },
  mounted() {
    this.facilities_search = getTypeObject({});
    this.getCities();
  },
  methods: {
    onOpen() {
      this.dialog = true;
    },
    getCities() {
      if(this.search.prefecture === null) {
        this.search.city = null;
        this.cities = [{text: 'ALL', value: null}];
      } else {
        this.cities = CITIES(this.search.prefecture);
        this.search.city = this.cities[0].value;
      }
    },
    onSearch() {
      const search = {
        ...this.search,
        ...Object.entries(this.facilities_search.commercial).filter(x => Number(x[1]) !== 0).reduce((total, e) => {total['has_'+e[0]] = e[1]; return total;}, {}),
        ...Object.entries(this.facilities_search.transport).filter(x => Number(x[1]) !== 0).reduce((total, e) => {total['has_'+e[0]] = e[1]; return total;}, {}),
      };
      this.onFetch(search).then(() => this.dialog = false)
    },
    onClear() {
      Object.entries(this.facilities_search.commercial)
        .map(([key, value]) => this.facilities_search.commercial[key] = 0);

     Object.entries(this.facilities_search.transport)
        .map(([key, value]) => this.facilities_search.transport[key] = 0);

     this.dialog = false;
    },
  },
};
</script>

<style></style>
