<template>
    <div>
        <v-layout row wrap>
            <v-flex xs12>
                <v-toolbar color="white" class="elevation-1">
                    <v-toolbar-title>
                        <v-icon color="secondary">chat</v-icon>
                        <span>&ensp;</span>
                        {{ $t('menu.Inquiries') }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <search-operations :searchFunc="onFetch"></search-operations>
                </v-toolbar>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
                <v-list two-line>
                    <div v-for="item in items" style="padding-bottom: 5px;">
                    <v-list-tile :style="'background-color: ' + (item.start_company_id === myCompanyId() ? 'lightgoldenrodyellow' : 'lightblue')" :key="item.id" :to="`/inquiries/${item.id}`">
                        <v-list-tile-avatar>
                            <v-flex xs6>
                                <v-icon v-if="getUnreads(item)>0" color="primary">info</v-icon>
                            </v-flex>
                            <v-flex xs6 style="border: black;">
                                <v-icon v-if="item.start_company_id === myCompanyId()">arrow_right</v-icon>
                                <v-icon v-else>arrow_left</v-icon>
                            </v-flex>

                        </v-list-tile-avatar>

                        <v-list-tile-content>
                            <v-list-tile-title>{{getRecipientsName(item)}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.subject}}</v-list-tile-sub-title>

                        </v-list-tile-content>
                        <v-list-tile-action>
                            <v-list-tile-action-text>{{item.message_count}}つのメッセージ
                                <v-spacer></v-spacer>
                                {{item.updated_at}}
                            </v-list-tile-action-text>
                        </v-list-tile-action>

                    </v-list-tile>
                    </div>
                </v-list>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import SearchOperations from "../../components/search/InquirySearchOperations";
    import Api from "../../api/Api";
    import {getUserDetails} from "../../utils/user";

    export default {
        data() {
            return {
                items: [],
                total: 0,
                rowsPerPage: 10,
                pagination: 1,
                company_id: null
            };
        },
        components: {
            SearchOperations
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            myCompanyId() {
                const user = getUserDetails();
                return Number(user.company_id);
            },
            getUnreads(inquiry) {
                return inquiry.company.find(x => x.id === this.myCompanyId()).inquiry_company.unread_count;
            },
            getRecipientsName(inquiry) {
                return inquiry.company.find(y => y.id !== this.myCompanyId()).name;
            },
            onFetch(company_id = null) {
                return Api.get(`/inquiry?mark_read=1&with[]=company${company_id ? `&filter[]=company(${company_id})` : ''}`).then(response => {
                    this.items = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                    this.total = Object.keys(this.items).length;
                });
            }
        },
    };
</script>

<style>
</style>
