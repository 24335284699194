import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../auth/Auth";
// Layout
import LayoutHome from "../layouts/layout-home";

// Pages
import Login from "../pages/Login";

// Application Routes
import routes from "./routes";
import Tos from "../pages/Tos";

// Initialize router
Vue.use(VueRouter);

// Router & Layout routes
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "main",
            component: LayoutHome,
            meta: {
                requiresAuth: true
            },
            children: routes()
        },
        {
            path: "/login",
            name: "login",
            component: Login
        }
        ,
        {
            path: "/tos",
            name: "tos",
            meta: {
                requiresAuth: true
            },
            component: Tos
        }
    ]

});

// Check user token existence and expiry
router.beforeEach((to, from, next) => {
    return Auth.checkOrRedirectLogin(to, from, next);
});

export default router;
