<template>
    <div>


        <v-card v-if="item" class="my-2">

            <v-card-title primary-title>
                <v-layout row justify-space-between dense>
                    <v-flex></v-flex>
                    <v-subheader v-if="!item.application || item.application.length === 0">
                        <mode-selection v-on:onSwitchMode="onSwitchMode"></mode-selection>
                    </v-subheader>
                    <div v-else style="color: red;">利用者がいるロケーションは編集不可</div>
                </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <LocationDetail :itemModel="item" :mode="mode" :submit-function="saveDraft" :errors="errors" type="rent" create="false"/>
        </v-card>
    </div>

</template>

<script>

    import ModeSelection from "../../components/ModeSelection";
    import LocationDetail from "../../components/LocationDetail";
    import Api from "../../api/Api";

    export default {
        components: {
            LocationDetail,
            ModeSelection
        },
        data() {
            return {
                item: null,
                mode: true,
                errors: []
            };
        },
        mounted() {
            const {location_id} = this.$route.params;
            this.onFetch(location_id);
        },
        methods: {
            createDraft() {
                const {location_id} = this.$route.params;
                Api.post(`/location/${location_id}/_copy`).then(response => {
                    this.item.id = response.data.id;
                    Api.get(`/location/${this.item.id}?with[]=photo&with[]=beacon&filter[]=draft()`).then(response => {
                        this.item = response.data
                    });
                });
            },
            saveDraft(data) {
                Api.put(`/location/${data.id}`, data).then(response => {
                    if(response.success) {
                        Api.post(`/location/${data.id}/_confirm`).then(response => {
                            this.$router.go(-1);
                        });
                    }else {
                        if (response.data && response.data.errors) {
                            this.errors = response.data.errors;
                        } else {
                            alert('System error');
                        }
                    }
                });
            },
            onFetch(location_id) {
                Api.get(`/location/${location_id}?with[]=photo&with[]=beacon&with[]=application&filter[]=application.active()`).then(response => {
                    this.item = response.data;
                });
            },
            onSwitchMode(val) {
                this.mode = val;
                if (!val) {
                    this.createDraft();
                }
            },
        },
    };
</script>

