// import "./bootstrap";
import Vue from "vue";// Components
import App from "./views/App";
import i18n from "./i18n";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

Vue.use(Vuetify, {
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        primary: "#00897b",
        secondary: "#546e7a",
        accent: "#00897b",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
    }
});

// Routes
import Routes from "./router/router";


Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('ja', {
        style: 'currency',
        currency: 'JPY',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
})


export default new Vue({
    i18n,
    el: "#app",
    render: h => h(App),
    router: Routes
});

