// Pages
import Applications from "../pages/applications/ApplyPage";
import Examinations from "../pages/examinations/Examinations";
import Home from "../pages/Home";
import LocationBorrow from "../pages/locationborrow/LocationsPage";
import LocationBorrowDetail from "../pages/locationborrow/LocationDetailsPage";
import LocationRent from "../pages/locationrent/LocationsPage";
import LocationRentDetail from "../pages/locationrent/LocationDetailsPage";
import NewLocationPage from "../pages/locationrent/NewLocationPage";
import LocationUsersPage from "../pages/locationrent/LocationUsersPage";
import LocationSearchPage from "../pages/locationborrow/LocationSearchPage";
import ApplyViewPage from "../pages/applications/ApplyViewPage";
import ApplyEditPage from "../pages/applications/ApplyEditPage";
import InquiryListPage from "../pages/inquiries/InquiryListPage";
import InquiryViewPage from "../pages/inquiries/InquiryViewPage";
import InquiryNewPage from "../pages/inquiries/InquiryNewPage";
import ReceiptDetailsPage from "../pages/receipts/ReceiptDetailsPage";
import ExaminationDetail from "../pages/examinations/ExaminationDetail";
import LocationSearchDetailsPage from "../pages/locationborrow/LocationSearchDetailsPage";

// Application Routes
const routes = () => [
    {
        path: "/home",
        name: "home",
        component: Home
    },
    {
        path: "/location/borrow",
        name: "location_borrow",
        component: LocationBorrow,
    },
    {
        path: "/location/search",
        name: "location_search",
        component: LocationSearchPage,
    },
    {
        path: "/location/search/:location_id",
        name: "location_search_detail",
        component: LocationSearchDetailsPage,
    },
    {
        path: "/location/borrow/:application_id/:location_id",
        name: "location_borrow_detail",
        component: LocationBorrowDetail,
    },
    {
        path: "/location/rent",
        name: "location_rent",
        component: LocationRent
    },
    {
        path: "/location/rent/new",
        name: "location_new_rent",
        component: NewLocationPage
    },
    {
        path: "/location/rent/:location_id",
        name: "location_rent_detail",
        component: LocationRentDetail,
    },
    {
        path: "/location/:location_id/users",
        name: "location_users_detail",
        component: LocationUsersPage,
    },
    {
        path: "/applications",
        name: "applications",
        component: Applications
    },
    {
        path: "/applications/:application_id",
        name: "edit_application",
        component: ApplyViewPage
    },
    {
        path: "/applications/:application_id/edit",
        name: "edit_application",
        component: ApplyEditPage
    },
    {
        path: "/examinations",
        name: "examinations",
        component: Examinations
    },
    {
        path: "/examinations/:company_id/:application_id",
        name: "examination_detail",
        component: ExaminationDetail
    },
    {
        path: "/receipts",
        name: "receipts",
        component: ReceiptDetailsPage
    },
    {
        path: "/inquiries",
        name: "inquiries",
        component: InquiryListPage
    },
    {
        path: "/inquiries/new/:company_id",
        name: "inquiry_create",
        component: InquiryNewPage
    },
    {
        path: "/inquiries/:inquiry_id",
        name: "inquiry_detail",
        component: InquiryViewPage
    },
];

export default routes;
