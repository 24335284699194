<template>
    <v-card-text>
        <v-flex xs12>
            <v-list-tile-title>{{$t('card_user.company_name')}}: {{ item.leaser.name }}</v-list-tile-title>
            <v-list-tile-title>{{$t('card_user.application_name')}}: {{ item.appli.name }}</v-list-tile-title>
            <v-list-tile-title>{{$t('card_user.usage_limit')}}: {{ item.usage_ends_at }}</v-list-tile-title>
        </v-flex>
    </v-card-text>
</template>
<script>

    export default {
        props: ["item"],
        methods: {},
    };
</script>
