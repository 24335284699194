<template>
    <div class="mx-0">
        <v-btn outline color="success" @click.stop="dialog = true" :disabled="disabled">{{$t('dialog.beacon_add')}}</v-btn>
        <v-dialog v-model="dialog" persistent max-width="700">
            <v-card>
                <v-card-title>
                    <v-icon>add</v-icon>
                    <span><strong>{{$t('dialog.beacon_add')}}</strong></span>
                </v-card-title>
                <v-card-text v-if="!loading">
                    <v-form>
                        <v-container>
                            <v-layout row wrap align-center class="mb-2">
                                <v-flex xs6>
                                    <v-text-field :label="$t('beacons.name')" v-model="item.name"></v-text-field>
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field :label="$t('beacons.uuid')" v-model="item.uuid"></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap align-center class="mb-2">
                                <v-flex xs6>
                                    <v-text-field :label="$t('beacons.major')" type="number" v-model="item.major"></v-text-field>
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field :label="$t('beacons.minor')" type="number" v-model="item.minor"></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-btn flat color="secondary" @click.stop="searchBeacons">{{ $t('common.search') }}</v-btn>
                                <v-spacer></v-spacer>
                                <v-checkbox @change="checkAll" :label="$t('common.all')" v-model="allCheck" ></v-checkbox>
                            </v-layout>
                        </v-container>
                    </v-form>
                    <v-layout row>
                        <v-flex xs12>
                            <v-list>
                                <card-beacon v-for="beacon in beacons" :key="beacon.id" :item="beacon"></card-beacon>
                            </v-list>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-text v-else>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-card-text>
                <v-card-actions>
                    <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat :loading="loading" @click.stop="add">{{ $t('common.add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import CardBeacon from './cards/CardBeacon'
    import Api, {ApiHelper} from "../api/Api";

    export default {
        props: ["disabled", "loading", "addBeacons", "curBeacons"],
        components: {
            CardBeacon
        },
        data() {
            return {
                dialog: false,
                item: {
                    name: '',
                    uuid: '',
                    major: '',
                    minor: '',
                },
                beacons: [],
                allCheck: false,
                loading: false
            };
        },
        watch: {
            curBeacons: function (newVal, oldVal) {
                this.searchBeacons();
            }
        },
        methods: {
            searchBeacons() {
                let url = ApiHelper.queryBuilder('/beacon', this.item);
                const beaconIds = this.curBeacons.map(x => x.id);
                this.loading = true;
                Api.get(url).then(response => {
                    this.beacons = response.data.filter(x=> !beaconIds.includes(x.id)).map(x => ({...x, use: false}));
                    this.allCheck = false;
                    this.loading = false;

                })
            },
            checkAll() {
                this.beacons = this.beacons.map(b => ({...b, use: this.allCheck}));
            },
            add() {
                this.addBeacons(this.beacons.filter(x => x.use === true));
                this.dialog = false;
            }
        }
    };
</script>

<style>
</style>
