<template>
    <div>
        <v-tooltip bottom>
            <v-btn icon slot="activator" @click.stop="onOpen">
                <v-icon>filter_list</v-icon>
            </v-btn>
            <span>{{ $t('common.search_operations') }}</span>
        </v-tooltip>
        <v-dialog v-model="dialog" max-width="550">
            <v-card>
                <v-card-title>
                    <v-icon color="secondary">search</v-icon>
                    <span>&ensp;</span>
                    <h4>{{ $t('common.search_operations') }}</h4>
                </v-card-title>
                <v-card-text>
                    <v-layout row>
                        <v-flex xs5 class="text-xs-right grey--text pt-2 pr-3">
                            <strong>{{ $t('inquiries.client') }}</strong>
                        </v-flex>
                        <v-flex xs10>
                            <v-select
                                    :items="items"
                                    item-text="name"
                                    item-value="id"
                                    v-model="search.client_id"
                                    autocomplete
                                    class="pt-0"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-btn flat color="secondary" @click.stop="onClear">{{ $t('common.clear') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click.stop="onSearch">{{ $t('common.search') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Api from "../../api/Api";

    export default {
        props: ["searchFunc"],
        data() {
            return {
                dialog: false,
                search: {client_id: null},
                items: []
            };
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            onOpen() {
                this.dialog = true;
            },
            onSearch() {
                this.searchFunc(this.search.client_id);
                this.dialog = false;
            },
            onClear() {
                this.search = {};
            },
            onFetch() {
                this.dialog = false;
                Api.get('/inquiry/company').then(response => {
                    this.items = response.data;
                })
            }
        }
    };
</script>

<style>
</style>
