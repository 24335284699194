import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ja",
  messages: {
    en: require("./lang/en.json"),
    ja: require("./lang/ja.json")
  }
});

export default i18n;
