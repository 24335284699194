<template>
    <div>
        <v-layout row wrap py-2 >
            <v-flex xs12>
                <div style="color: red;" v-if="sendToMyself()">自分に送るのは出来ません</div>
                        <v-card>
                            <v-card-title>
                                <v-icon color="secondary">chat</v-icon>
                                <span>&ensp;</span>
                                <h3>{{ $t('menu.Inquiries') }}</h3>
                            </v-card-title>
                            <v-card-text>
                        <v-flex>
                            <v-text-field
                                    :label="$t('inquiry.title')"
                                    v-model="item.subject"
                                    :error-messages="errors['subject']"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-textarea
                                rows="14"
                                    :label="$t('inquiry.body')"
                                    v-model="item.contents"
                                    :error-messages="errors['contents']"
                            ></v-textarea>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.close') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outline color="primary" @click.stop="onSubmit">送信</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <generic-dialog title="問い合わせ新規作成" description="問い合わせを作成しました" :dialog="create"
                        :success="{ text: $t('common.confirm'),  action: this.onConfirm}"></generic-dialog>
    </div>
</template>

<script>

    import Api from "../../api/Api";
    import {getUserDetails} from "../../utils/user";
    import GenericDialog from "../../components/GenericDialog";

    export default {
        components: {GenericDialog},
        data() {
            return {
                item: {
                    subject: '',
                    contents: '',
                },
                errors: [],
                create: false
            };
        },
        mounted() {
        },
        methods: {
            sendToMyself() {
                const myCompanyId = getUserDetails().company_id;
                return myCompanyId == this.getCompanyId();
            },
            getCompanyId() {
                const {company_id} = this.$route.params;
                return company_id;
            },
            onSubmit() {
                const company_id = this.getCompanyId();
                Api.post('/inquiry', {...this.item, company_id}).then(response => {
                    if(response.success) {
                        this.create = true;
                    } else {
                        this.errors = response.data.errors;
                    }
                })
            },
            onConfirm() {
                this.$router.push('/inquiries');
            }
        },
    };
</script>

<style>
</style>
