
<template>
    <v-app id="inspire">
        <v-content>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-toolbar color="primary" dark flat>
                                <v-toolbar-title>利用規約</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                １利用規約を読んでね
                            </v-card-text>
                            <v-card-actions>
                                <v-layout>
                                    <v-flex style="text-align: center;">
                                        <v-checkbox :error-messages="errors['tos']" class="justify-center" hide-details v-model="check" label="利用規約に同意する"></v-checkbox>
                                        <v-btn @click.stop="submit">Next</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
        <v-snackbar
            v-model="snackbar"
            :bottom="y === 'bottom'"
            :color="color"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="timeout"
            :top="y === 'top'"
            :vertical="mode === 'vertical'"
        >
            <span>Invalid Credentials</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
    </v-app>
</template>

<script>
    import Api from "../api/Api";

    export default {
        data() {
            return {
                check: false,
                errors: {}
            }
        },
        methods: {
            submit() {
                Api.put('/auth/user?tos=' + (this.check ? '1' : '0'), {}).then(response => {
                    if(response.success) {
                        this.$router.push('/home')
                    } else {
                        this.errors = response.data.errors;
                    }
                })
            }
        }
    };
</script>
