<template>
    <div>
        <v-card-title primary-title>
            <v-layout row justify-space-between dense>
                <v-flex>{{ item.name }}</v-flex>
                <v-subheader>
                    {{ this.prefecture }}
                </v-subheader>
                <v-subheader>{{ this.city }}</v-subheader>
            </v-layout>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-layout row wrap>

                <v-flex xs3 v-if="item.photo.length > 0">

                    <v-img
                        :src="`${item.photo[0].image_default}`"
                        height="125px"
                        width="125px"
                        position="center left"
                    >
                    </v-img>
                </v-flex>
                <v-flex xs3 v-else>
                    <v-img
                        src="/images/noimage.png"
                        height="125px"
                        width="125px"
                        position="center left"
                    >
                    </v-img>
                </v-flex>
                <v-flex xs3 v-for="i in 2" :key="i">
                    <v-list dense expand>
                        <v-list-tile
                            v-for="detail in Object.entries(getDetails(item, application)).splice(4 * (i-1), 4 * i)"
                            :key="detail[0]"
                        >
                            <v-list-tile-content>
                                <v-list-tile-sub-title justify-end>
                                    <span class="text--primary">{{ $t(`card_location.${detail[0]}`) }}</span>
                                    ：
                                    <span>{{ detail[1] }}</span>
                                </v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-flex>
                <v-flex v-if="type === 'rental' || type === 'receiptReceive'" xs3>
                    <v-btn v-if="item.application && item.application.filter( obj => (obj.leaser !== null)).length > 0" outline color="primary" :to="`/location/${this.item.id}/users`">{{$t('menu.Users')}}</v-btn>
                </v-flex>
                <v-flex v-else-if="type === 'search'" xs3>
                    <div style="text-align: center;" @click.self="parent">
                        <a href="javascript:void(0);" style="color:white;" @click.stop.prevent>
                            <v-checkbox hide-details   :label="'借りる'" v-model="item.use"></v-checkbox>
                        </a>
                    </div>
                    <v-btn outline color="primary" :to="`/inquiries/new/${item.company_id}`">{{$t('menu.Inquiries')}}</v-btn>
                </v-flex>
                <v-flex v-else-if="type === 'application' && !mode" xs3>
                    <v-checkbox :value="item.use" v-model="agree" @change="acceptTerms">
                        <span slot="label"><a @click.stop="showAgreeTerms = true">{{$t('card_location.prohibited_link')}}</a>{{$t('card_location.prohibited_link_agree')}}</span>
                    </v-checkbox>

                    <div :id="id" v-if="errors && errors.use && errors.use.find(item => Number(item.loc_id) ===id)"
                         style="color: red">
                        {{errors.use.find(item => Number(item.loc_id) ===id).message}}
                    </div>
                    <v-btn outline color="error" @click.prevent="removeSelf">{{$t('card_location.remove_from_application')}}</v-btn>
                </v-flex>
                <v-flex v-else-if="type === 'applicationDetail' && mode" xs3 style="display: flex; align-items: flex-end; flex-wrap: wrap;">
                    <v-btn outline color="primary" :to="`/inquiries/new/${item.company_id}`">{{$t('menu.Inquiries')}}</v-btn>
                    <rejection-reason-dialog :item="examination" v-if="item.application_location.status === statuses.STATUS_REJECTED"></rejection-reason-dialog>
                    <v-btn outline color="error" @click.prevent="removeSelf">{{$t('common.cancel')}}</v-btn>
                </v-flex>
                <v-flex v-else-if="type === 'examination'" xs3 style="display: flex; align-items: flex-end;">
                    <v-radio-group v-model="item.application_location.status">
                        <v-radio label="待ち" :value="0"></v-radio>
                        <v-radio label="許可" :value="2"></v-radio>
                        <v-radio label="却下" :value="1"></v-radio>
                    </v-radio-group>
                </v-flex>
            </v-layout>
        </v-card-text>
        <generic-dialog :dialog="showAgreeTerms" :success="{ text: $t('dialog.agree'), action: () => this.acceptTerms(true)}"
                        :fail="{ text: $t('dialog.disagree'), action: () => this.acceptTerms(false)}"
                        :description="item.rules"
                        :title="$t('dialog.prohibited_title')" type="forbidden"></generic-dialog>
        <generic-dialog :dialog="removeDialog" :success="{ text: '辞める', action: this.removeSelf}"
                        :fail="{ text: '辞めない', action: () => this.removeDialog = false}"
                        :description="getCancelText()" title="辞める確認"></generic-dialog>
    </div>
</template>
<script>
    import {getType} from "../../utils/facilities";
    import {CITY_SELECT_VALUE, LOCATION_APPLICATION_STATUS, LOCATION_APPLICATION_STATUS_MAP, LOCATION_STATUSES, PREFECTURE_SELECT_VALUE} from "../../utils/masterdata";
    import Api from "../../api/Api";
    import RejectionReasonDialog from "../RejectionReasonDialog";
    import GenericDialog from "../GenericDialog";
    import {getUserDetails} from "../../utils/user";
    import _env from "../../env";

    export default {
        components: {GenericDialog, RejectionReasonDialog},
        props: ["item", "application", "type", "removeLocation", "index", "mode", "examination", "changeStatus","errors","id"],
        mounted() {
            this.prefecture = PREFECTURE_SELECT_VALUE(this.item.prefecture).text;
            this.city = CITY_SELECT_VALUE(this.item.prefecture, this.item.city).text;
        },
        data() {
            return {
                prefecture: '',
                city: '',
                statuses: LOCATION_APPLICATION_STATUS,
                showAgreeTerms: false,
                removeDialog: false,
                agree:false
            }
        },
        methods: {
            getCancelText() {
                if(this.type === "applicationDetail") {
                    if (this.item.application_location.status === LOCATION_APPLICATION_STATUS.STATUS_APPROVED) {
                        return 'このロケーションを利用対象から除外しますか？';
                    } else {
                        return "このロケーションの申請を取り下げますか？";
                    }
                }
                return "このロケーションの申請を辞めますか？";
            },
            getDetails(item, application) {

                var usage_ends_at = '';

                if ('application_location' in item) {
                    usage_ends_at = item.application_location.usage_ends_at;
                } else if (application && 'application_location' in application) {
                    usage_ends_at = application.application_location.usage_ends_at;
                }
                if (!usage_ends_at && application && 'usage_ends_at' in application) {
                    usage_ends_at = application.usage_ends_at;
                }
                switch (this.type) {
                    case 'applicationDetail':
                        return {
                            type: getType(item),
                            company_name: item.company.name,
                            beacon_count: item.beacon_count,
                            fee: `${item.usage_fee}円/月`,
                            status: this.$t('shared.status.' + LOCATION_APPLICATION_STATUS_MAP[item.application_location.status])
                        };
                    case 'rental':
                        return {
                            type: getType(item),
                            company_name: item.company.name,
                            beacon_count: item.beacon_count,
                            fee: `${item.usage_fee}円/月`,
                            status: this.$t('shared.status.' + LOCATION_STATUSES[item.is_active].text)
                        };
                    case 'borrow':
                        return {
                            type: getType(item),
                            company_name: item.company.name,
                            beacon_count: item.beacon_count,
                            fee: `${item.usage_fee}円/月`,
                            usage_ends_at: usage_ends_at
                        };
                    case 'users':
                        return {
                            type: getType(item),
                            beacon_count: item.beacon_count,
                            fee: `${item.usage_fee}円/月`,
                        };
                    case 'examination':
                        return {
                            type: getType(item),
                            beacon_count: item.beacon_count,
                        };
                    case 'receipt':
                    case 'search':
                    case 'application':
                        return {
                            type: getType(item),
                            beacon_count: item.beacon_count,
                            fee: `${item.usage_fee}円/月`,
                            company_name: item.company.name,
                        };
                    case 'receiptReceive':
                        return {
                            type: getType(item),
                            beacon_count: item.beacon_count,
                            user_count: item.application.length,
                            company_name: item.company.name,
                            price: `${item.usage_fee}円/月`,
                            royalty: `${(1 - item.royalty) * item.usage_fee}円`,
                        };
                }
            },
            acceptTerms(result = null) {
                if (result !== null) {
                    this.item.use = result;
                } else {
                    this.item.use = !this.item.use;
                }
                Api.put(`/application/${this.application.id}/location/${this.item.id}?accept_rules=${this.item.use ? 1 : 0}`);
                this.showAgreeTerms = false;
            },
            removeSelf() {
                if(this.removeDialog) {
                    this.removeDialog = false;
                    this.removeLocation(this.index);
                } else {
                    this.removeDialog = true;
                }
            }
        }
    };
</script>
