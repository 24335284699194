<template>
    <div>
        <v-layout row wrap>
            <v-flex xs12>
                <v-toolbar color="white" class="elevation-1">
                    <v-toolbar-title>
                        <span class="caption">{{ $t("common.total") }}</span>
                        {{ total }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-checkbox hide-details @change="checkAll" :label="$t('common.all')" v-model="allCheck"></v-checkbox>
                    <v-btn v-if="this.getChecked().length > 0" outline color="primary" @click.stop="createApplication">レンタル申請</v-btn>
                    <location-search :onFetch="onFetch"></location-search>
                </v-toolbar>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
                <v-card
                        v-for="location in items" :key="location.id"
                        class="list-card"
                        :to="`${$route.path}/${location.id}`"
                        @click.self="parent"
                >
                    <card-location :item="location" :application="location.application" type="search"></card-location>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import CardLocation from "../../components/cards/CardLocation";
    import Api, {ApiHelper} from "../../api/Api";
    import LocationSearch from "../../components/search/LocationsSearchOperations";

    export default {
        components: {
            LocationSearch,
            CardLocation
        },
        mounted() {
            this.onFetch({});
        },
        watch: {
            pagination() {
                this.onFetch();
            },
            rowsPerPage() {
                this.pagination = 1;
                this.onFetch();
            }
        },
        data() {
            return {
                total: 0,
                rowsPerPage: 30,
                pagination: 1,
                items: [],
                allCheck: false
            };
        },
        computed: {
            pages() {
                return Math.ceil(this.total / this.rowsPerPage);
            }
        },
        methods: {
            onFetch(search) {
                const url = ApiHelper.queryBuilder('/location?with[]=photo&with[]=company&filter[]=owner(false)', search);
                return Api.get(url, {
                        params: Object.assign(
                            {
                                per_page: this.rowsPerPage,
                                page: this.pagination
                            },
                        )
                    })
                    .then(response => {
                        this.items = response.data.map(b => ({...b, use: false}));
                        this.total = response.data.length;
                    });
            },
            getChecked() {
              return this.items.filter(x => x.use === true || x.use === 1);
            },
            createApplication() {
                const items = this.getChecked();
                Api.post('/application').then(response => {
                    const promises = [];
                    items.map(x => {
                        promises.push(Api.post(`/application/${response.data.id}/location?id=${x.id}`));
                    });
                    Promise.all(promises).then(() => this.$router.push(`/applications/${response.data.id}/edit`));
                })
            },
            checkAll() {
                this.items = this.items.map(b => ({...b, use: this.allCheck}));
            },
        }
    };
</script>

<style scoped></style>
